import { LifeCycles, pathToActiveWhen, registerApplication } from 'single-spa';

import {
  DockConfiguration,
  getSingleSpaMicrofrontend,
  SingleSpaProps,
  getSDK,
} from '@fnz-dock/core';

import { cleanDomElement, getDomElement } from './mount-element';

type MicrofrontendConfiguration = DockConfiguration['microfrontends'][number];
type ReadyFn = (
  configuration: MicrofrontendConfiguration,
  navigatorRenderDependant?: boolean,
) => boolean;

const MEGABYTE = 1048576; // 1MB
const isTooBig = async (configuration: MicrofrontendConfiguration) => {
  const TOO_BIG = MEGABYTE * 0.3; // set limit to 300 kb
  const response = await fetch(configuration.url, { method: 'HEAD' });
  const size = response.headers.get('Content-Length');
  if (!size) {
    return true;
  }
  return size && parseInt(size, 10) > TOO_BIG;
};

export const processMicrofrontends = async (
  microfrontends: DockConfiguration['microfrontends'],
  layoutReady: ReadyFn,
) => {
  await Promise.allSettled(
    microfrontends.map((configuration) => {
      return processMicrofrontend(configuration, layoutReady);
    }),
  );
};

const processMicrofrontend = async (
  microfrontendConfiguration: MicrofrontendConfiguration,
  layoutReady: ReadyFn,
) => {
  if (
    (await isTooBig(microfrontendConfiguration)) &&
    microfrontendConfiguration.id !== '@fnz-dock/navigator'
  ) {
    // if too big run in background
    console.warn(
      `Bundle size of ${microfrontendConfiguration.id} is too big, setup run in the background`,
    );
    setupMicrofrontend(microfrontendConfiguration);
  } else {
    await setupMicrofrontend(microfrontendConfiguration);
  }
  await registerMicrofrontend(microfrontendConfiguration, layoutReady);
};

const setupMicrofrontend = async (
  microfrontendConfiguration: MicrofrontendConfiguration,
) => {
  const module = await getSingleSpaMicrofrontend(microfrontendConfiguration.id);
  if ('getDockLifecycles' in module) {
    const { setup } = await module.getDockLifecycles();
    const sdk = await getSDK(microfrontendConfiguration, false);
    await setup(microfrontendConfiguration, sdk);
  } else {
    console.log(microfrontendConfiguration.id, 'does not have setup function');
  }
};

const verifyMicrofrontend = (
  singleSpaLifecycles: LifeCycles<SingleSpaProps>,
) => {
  if (
    !Array.isArray(singleSpaLifecycles.unmount) ||
    singleSpaLifecycles.unmount.length < 2
  ) {
    console.warn(
      'Most likely props.cleanup call is missing. It is important to call it on unmount',
    );
  }
};

const registerMicrofrontend = async (
  microfrontendConfiguration: MicrofrontendConfiguration,
  layoutReady: ReadyFn,
) => {
  // application
  if (microfrontendConfiguration.type === 'application') {
    const root = microfrontendConfiguration.path === '/';
    const activeWhen = pathToActiveWhen(microfrontendConfiguration.path, root);
    registerApplication<SingleSpaProps>({
      name: microfrontendConfiguration.id,
      app: async () => {
        const module = await getSingleSpaMicrofrontend(
          microfrontendConfiguration.id,
        );
        const lifecycles = await module.getSingleSpaLifecycles();
        verifyMicrofrontend(lifecycles);
        return lifecycles;
      },
      activeWhen: (location) => {
        return layoutReady(microfrontendConfiguration) && activeWhen(location);
      },
      customProps: {
        cleanup() {
          return cleanDomElement(microfrontendConfiguration);
        },
        sdk: () => getSDK(microfrontendConfiguration),
        configuration: {
          ...microfrontendConfiguration,
        },
        get domElement() {
          return getDomElement(microfrontendConfiguration);
        },
      },
    });
  }
  // widget
  if (microfrontendConfiguration.type === 'widget') {
    registerApplication({
      name: microfrontendConfiguration.id,
      app: async () => {
        const module = await getSingleSpaMicrofrontend(
          microfrontendConfiguration.id,
        );
        const lifecycles = await module.getSingleSpaLifecycles();
        verifyMicrofrontend(lifecycles);
        return lifecycles;
      },
      activeWhen: () => layoutReady(microfrontendConfiguration, true),
      customProps: {
        cleanup() {
          return cleanDomElement(microfrontendConfiguration);
        },
        sdk: () => getSDK(microfrontendConfiguration),
        configuration: microfrontendConfiguration,
        get domElement() {
          return getDomElement(microfrontendConfiguration);
        },
      },
    });
  }
};
